import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {Contact} from './structure/pages/contact';
import {About} from './structure/about/about';
import {Services} from './structure/services/services';
import {Impressum} from './structure/impressum/impressum';

const routes: Routes = [
  {path: "contact", component: Contact},
  {path: "about", component: About},
  {path: "services", component: Services},
  {path: "impressum", component: Impressum}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
export const routingComponents = [Contact,About,Services, Impressum]
