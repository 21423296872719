import { Component } from '@angular/core';

@Component({
    selector: 'about',
    templateUrl: './about.html',
    styleUrls: ['./about.css']
  })

  export class About {
    
  }
