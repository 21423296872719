import { Component } from '@angular/core';

@Component({
    selector: 'services',
    templateUrl: './services.html',
    styleUrls: ['./services.css']
  })

  export class Services {
    
  }