import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'contact',
    templateUrl: './contact.html',
    styleUrls: ['./contact.css']
  })

  export class Contact implements OnInit {

    show_form=false;
    get_form(){
      if(!this.show_form) this.show_form=true;
      else this.show_form=false;
    }

    constructor() { }
 
  ngOnInit() {
  }
  }